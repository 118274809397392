import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import axios from 'axios';
import useForm from "react-hook-form";
import "./JoinForm.scss";

const JoinForm = () => {

  const [emailSent, setEmailSent] = useState(false);
  const { register, errors, handleSubmit } = useForm({});

  const onSubmit = data => {

    const marked_difficulties = Object.keys(data).filter((field) => data[field] === true);

    const emailData = {
      email: data.email,
      phone: data.phone,
      address: data.address + ", " + data.city + ", " + data.state,
      marked_difficulties: marked_difficulties,
      difficulties: data.difficulties,
      profile: data.profile
    }

    axios.post(
      'https://liteme.com.br/mail', 
      JSON.stringify(emailData), 
      { headers: { 
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        } 
      }
    ).then( setEmailSent(true) );
  };

  return (
    <div id="join-form-element-div">
      { !emailSent &&
        <div className="join-form-container" id="join-form-container">
          <div className="join-form-header-wrapper">
            <div className="join-form-header">Vamos te auxiliar a economizar energia de modo inteligente.</div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-section">
              <label htmlFor="profile" className="section-header">
                Qual o seu perfil?
              </label>
              <div className="form-input-area">
                <Row className="profile-options">
                  <Col className="profile-options-col" xs={6} sm={6} md={6} lg={4} xl={4}>
                    <input className='radio-btn' name="profile" type="radio" id="radio-btn-1" value="Residência" ref={register({ required: true })}/>
                    <label className="profile-label residence" htmlFor="radio-btn-1"></label>
                  </Col>
                  <Col className="profile-options-col" xs={6} sm={6} md={6} lg={4} xl={4}>
                    <input className='radio-btn' name="profile" type="radio" id="radio-btn-2" value="Empresa" ref={register({ required: true })}/>
                    <label className="profile-label commerce" htmlFor="radio-btn-2"></label>
                  </Col>
                  <Col className="profile-options-col" xs={6} sm={6} md={6} lg={4} xl={4}>
                    <input className='radio-btn' name="profile" type="radio" id="radio-btn-3" value="Indústria" ref={register({ required: true })}/>
                    <label className="profile-label industry" htmlFor="radio-btn-3"></label>
                  </Col>
                  <Col className="profile-options-col" xs={6} sm={6} md={6} lg={4} xl={4}>
                    <input className='radio-btn' name="profile" type="radio" id="radio-btn-4" value="Concessionária" ref={register({ required: true })}/>
                    <label className="profile-label concessionary" htmlFor="radio-btn-4"></label>
                  </Col>
                  <Col className="profile-options-col" xs={6} sm={6} md={6} lg={4} xl={4}>
                    <input className='radio-btn' name="profile" type="radio" id="radio-btn-5" value="Campus" ref={register({ required: true })}/>
                    <label className="profile-label campus" htmlFor="radio-btn-5"></label>
                  </Col>
                  <Col className="profile-options-col" xs={6} sm={6} md={6} lg={4} xl={4}>
                    <input className='radio-btn' name="profile" type="radio" id="radio-btn-6" value="Outro" ref={register({ required: true })}/>
                    <label className="profile-label other" htmlFor="radio-btn-6"></label>
                  </Col>
                </Row>
              </div>

              {errors.profile && <p className="profile-input-error">Selecione um perfil</p>}
            </div>

            <div className="form-section">
              <label className="section-header">
                Marque suas dificuldades:
              </label>
              <div className="form-input-area"> 
                <input className='checkbox-btn' type="checkbox" id="checkbox-1" name="Quero entender minha conta de energia" ref={register} />
                <label htmlFor="checkbox-1">Quero entender minha conta de energia.</label><br></br>

                <input className='checkbox-btn' type="checkbox" id="checkbox-2" name="Quero saber quais equipamentos mais consomem energia" ref={register} />
                <label htmlFor="checkbox-2">Quero saber quais equipamentos mais consomem energia.</label><br></br>

                <input className='checkbox-btn' type="checkbox" id="checkbox-3" name="Quais os horários ideais para utilizar equipamentos" ref={register} />
                <label htmlFor="checkbox-3">Quais os horários ideais para utilizar equipamentos.</label><br></br>

                <input className='checkbox-btn' type="checkbox" id="checkbox-4" name="Preciso receber alertas sobre anomalias de consumo ou sobre falhas no cumprimento de metas" ref={register} />
                <label htmlFor="checkbox-4">Preciso receber alertas sobre anomalias de consumo ou sobre falhas no cumprimento de metas.</label><br></br>

                <input className='checkbox-btn' type="checkbox" id="checkbox-5" name="Quero controlar equipamentos, atuando remotamente ou ajustando períodos em que estão habilitados" ref={register} />
                <label htmlFor="checkbox-5">Quero controlar equipamentos, atuando remotamente ou ajustando períodos em que estão habilitados.</label><br></br>

                <input className='checkbox-btn' type="checkbox" id="checkbox-6" name="Outras" ref={register} />
                <label htmlFor="checkbox-6">Outras.</label><br></br>
              </div>
            </div>

            <div className="form-section">
              <label htmlFor="difficulties" className="section-header">
                Fale mais detalhes sobre suas dificuldades:
              </label>
              <div className="form-input-area"> 
                <textarea className="form-input-textarea" name="difficulties" ref={register} />
              </div>
            </div>

            <div className="form-section">
              <label className="section-header">
                Qual o seu endereço?
              </label>
              <div className="form-input-area">
                <div className="form-text-input">
                  <label className="form-text-input-header" htmlFor="city">Cidade</label>
                  <input className="form-text-input-area" type="text" name="city" ref={register({required: true})} />
                </div>
                {errors.city && <p className="text-input-error">Insira sua cidade</p>}

                <div className="form-text-input">
                  <label className="form-text-input-header" htmlFor="state">Estado</label>
                  <input className="form-text-input-area" type="text" name="state" ref={register({required: true})} />
                </div>
                {errors.state && <p className="text-input-error">Insira seu estado</p>}

                <div className="form-text-input">
                  <label className="form-text-input-header" htmlFor="address">Endereço</label>
                  <input className="form-text-input-area" type="text" name="address" ref={register({required: true})} />
                </div>
                {errors.address && <p className="text-input-error">Insira seu endereço</p>}
              </div>
            </div>

            <div className="form-section">
              <label className="section-header">
                Deixe o seu contato:
              </label>
              <div className="form-input-area">
                <div className="form-text-input">
                  <label className="form-text-input-header" htmlFor="email">Email</label>
                  <input className="form-text-input-area" type="text" name="email" ref={register({required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} />
                </div>
                {errors.email && <p className="text-input-error">Insira um endereço de email válido</p>}

                <div className="form-text-input">
                  <label className="form-text-input-header" htmlFor="phone">Telefone</label>
                  <input className="form-text-input-area" type="tel" name="phone" ref={register({required: true})} />
                </div>
                {errors.phone && <p className="text-input-error">Insira seu telefone</p>}
              </div>
            </div>

            <div className="join-submit-button-wrapper">
              { (errors.profile || errors.city || errors.state || errors.address || errors.email || errors.phone) 
              && <div className="form-has-errors">Insira os campos obrigatórios</div> }
              <input className="join-submit-button" type="submit" value="Enviar"/>
            </div>
          </form>
        </div>
      }
      { emailSent && 
        <div className="page-section email-sent-page">
          <div className="email-sent-page-text">
            Mensagem enviada com sucesso!
            <br/>
            Entraremos em contato em breve!
          </div>
        </div>
      }
    </div>
  );
};

export default (JoinForm);